<template>
  <div class="modal modal_wrap">
    <div class="modal_popup">
      <p class="text text_white">Order was successfully cloned with "Draft" status. Please, check out cloned order on this page.
        <span class="modal__close" @click="close">
         <svg-icon name="times"/>
      </span>
      </p>
    </div>
  </div>
</template>

<script>

  import router from 'vue-router';
  import {mapGetters} from 'vuex';

  export default {
    name: "CloneOrder",
    methods: {
      close() {
        this.$emit('reRenderLocations')
        this.$store.commit('orderPreview/cloneWindow', false);
      },
    },
  }

</script>

<style scoped>
  .text_white{
    margin: 1.5rem 0 .5rem;
  }
</style>
